import { combineReducers } from "redux";

import answers from "./answers";
import currentQuestion from "./currentQuestion";
import questions from "./questions"

const reducer = combineReducers({
  questions,
  currentQuestion,
  answers
});

export default reducer;
